import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "../components/meta/Seo";
import Anchor from "../components/elements/Anchor";
import { isBrowser } from "../utilities/checkEnvironment";

const AboutPage = ({ data }) => {
  if (!data) return null;
  const { page, settings } = data;
  const contact = page.data;
  const footerΜenu = settings.data.footer_menu;
  const [customMargin, setCustomMargin] = useState(80);

  useEffect(() => {
    if (isBrowser) {
      const pageTitleElem = document.querySelector(".page-title");
      if (pageTitleElem) {
        setCustomMargin(pageTitleElem.getClientRects()[0].x + 80);
        // window.addEventListener("resize", () =>
        //   setCustomMargin(pageTitleElem.getClientRects()[0].x + 80)
        // );
        // return () =>
        //   window.removeEventListener("resize", () =>
        //     setCustomMargin(pageTitleElem.getClientRects()[0].x + 80)
        //   );
      }
    }
    return null;
  }, []);

  return (
    <>
      <Seo page={page} />
      <div className="contact-container bg-primary text-light site d-flex flex-column justify-content-between">
        <div>
          <Container className="ps-sm-5">
            <Anchor href="/">
              <GatsbyImage
                image={settings.data.logo.gatsbyImageData}
                alt="logo"
                className="logo-btn mt-4"
              />
            </Anchor>
            <h1 className="page-title pt-5 mb-0 text-light">{contact.title}</h1>
          </Container>
          <div
            className="text-light mt-5 mb-3"
            style={{ marginLeft: customMargin }}
          >
            <Row>
              <Col lg={4} className="mb-4">
                <Anchor
                  href={footerΜenu[0].link.url}
                  target={footerΜenu[0].link.target}
                  title={footerΜenu[0].label}
                  className="footer-address text-light link-underline-hover underline-light"
                >
                  {footerΜenu[0].label}
                </Anchor>
              </Col>

              <Col lg={5} className="d-flex flex-column mb-4">
                <Anchor
                  href={footerΜenu[3].link.url}
                  target={footerΜenu[3].link.target}
                  title={footerΜenu[3].label}
                  className="mb-2 text-light link-underline-hover underline-light"
                >
                  {footerΜenu[3].label}
                </Anchor>
                <Anchor
                  href={footerΜenu[4].link.url}
                  target={footerΜenu[4].link.target}
                  title={footerΜenu[4].label}
                  className="text-light link-underline-hover underline-light"
                >
                  {footerΜenu[4].label}
                </Anchor>
              </Col>
              <Col lg={3} className="mb-4 d-flex flex-column">
                <Anchor
                  href={footerΜenu[1].link.url}
                  target={footerΜenu[1].link.target}
                  title={footerΜenu[1].label}
                  className="mb-2 text-light link-underline-hover underline-light"
                >
                  {footerΜenu[1].label}
                </Anchor>
                <Anchor
                  href={footerΜenu[2].link.url}
                  target={footerΜenu[2].link.target}
                  title={footerΜenu[2].label}
                  className="text-light link-underline-hover underline-light"
                >
                  {footerΜenu[2].label}
                </Anchor>
              </Col>
            </Row>
          </div>
        </div>
        <div
          className="contact-image-wraper d-flex justify-content-end"
          style={{ marginLeft: customMargin }}
        >
          <p>Photographer: {contact.photographer_name}</p>
          <GatsbyImage
            image={contact.contact_image_mobile.gatsbyImageData}
            alt={contact.contact_image_mobile.alt || "Contact Us"}
            className="contact-image d-md-none"
          />
          <GatsbyImage
            image={contact.contact_image.gatsbyImageData}
            alt={contact.contact_image.alt || "Contact Us"}
            className="contact-image d-none d-md-block"
          />
        </div>
      </div>
    </>
  );
};

export const query = graphql`
  query contactQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicContact(lang: { eq: $lang }) {
      data {
        photographer_name
        meta_title
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        title
        contact_image {
          gatsbyImageData(placeholder: BLURRED)
          alt
        }
        contact_image_mobile {
          gatsbyImageData(placeholder: BLURRED)
          alt
        }
      }
    }
  }
`;

export default AboutPage;
